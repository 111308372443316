import { render, staticRenderFns } from "./TableComponent.vue?vue&type=template&id=591af1ba&scoped=true&"
import script from "./TableComponent.vue?vue&type=script&lang=ts&"
export * from "./TableComponent.vue?vue&type=script&lang=ts&"
import style0 from "./TableComponent.vue?vue&type=style&index=0&id=591af1ba&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "591af1ba",
  null
  
)

export default component.exports