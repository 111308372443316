/* tslint:disable */
/* eslint-disable */
/**
 * OMB Api
 * This file contains the information needed to generate all Api calls for the project.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Project,
    ProjectFromJSON,
    ProjectFromJSONTyped,
    ProjectToJSON,
    ProjectGroup,
    ProjectGroupFromJSON,
    ProjectGroupFromJSONTyped,
    ProjectGroupToJSON,
    Repair,
    RepairFromJSON,
    RepairFromJSONTyped,
    RepairToJSON,
} from './';

/**
 * 
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * 
     * @type {number}
     * @memberof Message
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    from?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    to?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    content?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Message
     */
    done?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    projectName?: string;
    /**
     * 
     * @type {ProjectGroup}
     * @memberof Message
     */
    projectGroup?: ProjectGroup;
    /**
     * 
     * @type {Project}
     * @memberof Message
     */
    project?: Project;
    /**
     * 
     * @type {Repair}
     * @memberof Message
     */
    repair?: Repair;
    /**
     * 
     * @type {Date}
     * @memberof Message
     */
    createdAt?: Date;
}

export function MessageFromJSON(json: any): Message {
    return MessageFromJSONTyped(json, false);
}

export function MessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): Message {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'from': !exists(json, 'from') ? undefined : json['from'],
        'to': !exists(json, 'to') ? undefined : json['to'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'content': !exists(json, 'content') ? undefined : json['content'],
        'done': !exists(json, 'done') ? undefined : json['done'],
        'projectName': !exists(json, 'projectName') ? undefined : json['projectName'],
        'projectGroup': !exists(json, 'projectGroup') ? undefined : ProjectGroupFromJSON(json['projectGroup']),
        'project': !exists(json, 'project') ? undefined : ProjectFromJSON(json['project']),
        'repair': !exists(json, 'repair') ? undefined : RepairFromJSON(json['repair']),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
    };
}

export function MessageToJSON(value?: Message | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'from': value.from,
        'to': value.to,
        'type': value.type,
        'content': value.content,
        'done': value.done,
        'projectName': value.projectName,
        'projectGroup': ProjectGroupToJSON(value.projectGroup),
        'project': ProjectToJSON(value.project),
        'repair': RepairToJSON(value.repair),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
    };
}


