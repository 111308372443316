

import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseDialog from '@/components/dialogs/BaseDialog.vue';
import {
    InputResponse,
    Message,
    Project,
    ProjectGroup,
    Repair,
} from '@/clients/omb-api';
import Api from '@/assets/ts/Api';
import Loading from '@/components/Loading.vue';
import availableRoutes from '@/plugins/availableRoutes';
import InputButton from '@/components/input/InputButton.vue';

@Component({
    components: {
        InputButton,
        Loading,
        BaseDialog,
    },
})
export default class MessageDialog extends Vue {

    @Prop({ default: [] })
    private recipients!: string[];

    @Prop()
    private projectGroup?: ProjectGroup;

    @Prop()
    private project?: Project;

    @Prop()
    private repair?: Repair;

    private content = '';
    private submitted: boolean = false;
    private success: boolean = false;
    private showErrors: boolean = false;
    private allErrors: string = '';

    private async send() {
        this.submitted = true;
        this.showErrors = false;
        this.allErrors = '';

        try {
            for (let i = 0; i < this.recipients.length; i += 1) {
                const message: Message = {
                    done: false,
                    to: this.recipients[i],
                    projectGroup: this.projectGroup,
                    project: this.project,
                    repair: this.repair,
                    content: this.content,
                };

                // eslint-disable-next-line no-await-in-loop
                const response: InputResponse = await Api.omb.messagePost(message);
                if (response.error) {
                    console.warn(response);
                    this.submitted = false;
                    if (response.problems) {
                        console.warn(Object.values(response.problems));
                        Object.values(response.problems)
                            .filter((p) => !this.allErrors.includes(p))
                            .forEach((problem) => {
                                this.allErrors = this.allErrors.concat(problem)
                                    .concat('\n');
                            });
                    }
                    this.showErrors = true;
                    this.$forceUpdate();
                }
            }
            if (!this.showErrors) {
                this.success = true;
                await this.delay(1000);
                this.$emit('close');
            }
        } catch (e: any) {
            if (e.status === 401) {
                Api.initServices('');
                await this.$router.push({ name: availableRoutes.login });
            }
        }
    }

    private delay(ms: number) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

}
