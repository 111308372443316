

import { Component, Prop, Vue } from 'vue-property-decorator';
import {
    HistoryEntry,
    ProjectGroup,
    Repair,
    Upload,
    User,
} from '@/clients/omb-api';
import { ROLE } from '@/assets/ts/enum/role';
import InputCheckbox from '@/components/input/InputCheckbox.vue';
import Api from '@/assets/ts/Api';
import InputButton from '@/components/input/InputButton.vue';
import MessageDialog from '@/components/dialogs/MessageDialog.vue';
import BaseDialog from '@/components/dialogs/BaseDialog.vue';
import History from '@/components/History.vue';
import clone from 'clone';
import AddUploadsDialog from '@/components/dialogs/AddUploadsDialog.vue';
import SimpleUploadDownloader from '@/components/SimpleUploadDownloader.vue';
import { MESSAGE_TYPE } from '@/assets/ts/enum/message_type';
import availableRoutes from '@/plugins/availableRoutes';
import LocationUtils from '@/assets/ts/location_utils';
import { v4 as uuidv4 } from 'uuid';
import RepairDailyWageReports from '@/views/repairs/RepairDailyWageReports.vue';

@Component({
    components: {
        RepairDailyWageReports,
        SimpleUploadDownloader,
        AddUploadsDialog,
        History,
        BaseDialog,
        MessageDialog,
        InputButton,
        InputCheckbox,
    },
})
export default class RepairsTable extends Vue {

    private ROLE = ROLE;
    private MESSAGE_TYPE = MESSAGE_TYPE;

    @Prop({ required: true })
    private repairs!: Repair[];

    @Prop({ required: true })
    private projectGroups!: ProjectGroup[];

    @Prop({ default: false })
    private archive!: boolean;

    @Prop({ default: false })
    private preview!: boolean;

    @Prop({ default: true })
    private editable!: boolean;

    @Prop({ default: 0 })
    private distanceFromLat!: number;

    @Prop({ default: 0 })
    private distanceFromLng!: number;

    private id = uuidv4();

    private currentUser: User = {};

    private expandedRow = -1;

    private descriptionBeforeUpdate?: string;
    private descriptionUpdateSuccess = false;
    private descriptionUpdateError = false;

    private assignError = false;

    private messageRepair: Repair | null = null;
    private messageProjectManagers: string[] = [];

    private repairToDelete: Repair | null = null;
    private repairToReset: Repair | null = null;
    private repairToAddUploads: Repair | null = null;

    private headerTranslateY = 0;

    private get canEdit(): boolean {
        return !!this.currentUser.id && [ROLE.ADMINISTRATOR, ROLE.PLANNING, ROLE.ASSEMBLY, ROLE.EASSEMBLY].includes(this.currentUser.role as ROLE);
    }

    private async created() {
        this.currentUser = await Api.omb.getCurrentUser();
    }

    private mounted() {
        const appBody = document.getElementById('scrollableAppBody');
        if (appBody) {
            appBody.addEventListener('scroll', this.calculateHeaderTranslateY);
        }
    }

    private destroyed() {
        const appBody = document.getElementById('scrollableAppBody');
        if (appBody) {
            appBody.removeEventListener('scroll', this.calculateHeaderTranslateY);
        }
    }

    private getDistanceString(repair: Repair) {
        if (!this.distanceFromLat || !this.distanceFromLng || !repair.lat || !repair.lng) {
            return '-';
        }

        const distance = LocationUtils.getDistance(this.distanceFromLat, this.distanceFromLng, repair.lat, repair.lng);

        return `${Math.round(distance)}km`;
    }

    private getLocation(repair: Repair) {
        if (!repair.zipCode && !repair.city) return '-';
        return `${repair.zipCode || ''} ${repair.city || ''}`;
    }

    private getManagers(repair: Repair) {
        return repair.managers && repair.managers.length ? repair.managers.map((manager) => manager.abbreviation)
            .join(', ') : '-';
    }

    private getAssemblerString(repair: Repair) {
        if (!repair.assembler) return '-';

        return `${repair.assembler.firstName} ${repair.assembler.name}`;
    }

    private getHistory(repair: Repair): HistoryEntry[] {
        if (!repair.history || !repair.history.length) return [];

        const history = clone(repair.history);
        history.reverse();
        return history;
    }

    private edit(repair: Repair) {
        this.$emit('edit', repair);
    }

    private deleteRepair() {
        if (!this.repairToDelete) return;

        this.repairToDelete.deleted = true;
        Api.omb.saveRepair(this.repairToDelete)
            .then(() => this.$emit('delete', this.repairToDelete))
            .finally(() => this.repairToDelete = null);
    }

    private resetRepair() {
        if (!this.repairToReset) return;

        const repair = this.repairToReset;

        const partsBefore = repair.parts;
        const processingBefore = repair.processing;
        const completedBefore = repair.completed;

        repair.parts = false;
        repair.processing = false;
        repair.completed = false;
        repair.archive = false;
        repair.archivedAt = undefined;
        Api.omb.saveRepair(repair)
            .then((updated) => {
                this.$emit('reset', updated);
            })
            .catch(() => {
                repair.parts = partsBefore;
                repair.processing = processingBefore;
                repair.completed = completedBefore;
            })
            .finally(() => this.repairToReset = null);
    }

    private onPartsCheckboxChange(value: boolean, repair: Repair) {
        Api.omb.repairToggleParts(repair.id || 0)
            .catch(() => {
                this.$nextTick(() => repair.parts = !value);
            });
    }

    private onProcessingCheckboxChange(value: boolean, repair: Repair) {
        Api.omb.repairToggleProcessing(repair.id || 0)
            .catch(() => {
                this.$nextTick(() => repair.processing = !value);
            });
    }

    private onCompletedCheckboxChange(value: boolean, repair: Repair) {
        Api.omb.repairToggleCompleted(repair.id || 0)
            .then((updated) => {
                if (repair.completed) {
                    this.$emit('complete', updated);
                }
            })
            .catch(() => {
                this.$nextTick(() => repair.completed = !value);
            });
    }

    private onArchive(repair: Repair) {
        Api.omb.repairToArchive(repair.id || 0)
            .then((updated) => {
                repair.archive = true;
                this.$emit('archive', updated);
            })
            .catch(() => {
                repair.archive = false;
            });
    }

    private toggleRow(index: number) {
        this.descriptionUpdateSuccess = false;
        this.descriptionUpdateError = false;

        this.assignError = false;

        if (this.expandedRow === index) {
            this.expandedRow = -1;
        } else {
            this.expandedRow = index;
        }
    }

    private onDescriptionFocus(repair: Repair) {
        this.descriptionBeforeUpdate = repair.description;
    }

    private onDescriptionBlur(repair: Repair) {
        if (repair.description === this.descriptionBeforeUpdate) return;

        this.descriptionUpdateSuccess = false;
        this.descriptionUpdateError = false;
        Api.omb.repairUpdateDescription(repair.id || 0, repair.description || '')
            .then(() => {
                this.descriptionUpdateSuccess = true;
                setTimeout(() => this.descriptionUpdateSuccess = false, 2000);
            })
            .catch(() => {
                this.descriptionUpdateError = true;
                repair.description = this.descriptionBeforeUpdate;
            })
            .finally(() => this.descriptionBeforeUpdate = repair.description);
    }

    private assignSelf(repair: Repair) {
        this.assignError = false;
        Api.omb.repairAssignSelf(repair.id || 0)
            .then(() => repair.assembler = this.currentUser)
            .catch(() => this.assignError = true);
    }

    private openFileUpload(repair: Repair) {
        this.repairToAddUploads = repair;
    }

    private showRepairsAndProjectsNear(repair: Repair) {
        this.$router.push({
            name: availableRoutes.repairsAndProjectsNear,
            query: { repair: `${repair.id}` },
        });
    }

    private addUploadsToRepair(uploads: Upload[]) {
        if (!this.repairToAddUploads) return;

        const repair = this.repairToAddUploads;
        const currentUploads = this.repairToAddUploads.uploads || [];
        const newUploads = currentUploads.concat(uploads);

        Api.omb.repairUpdateUploads(this.repairToAddUploads.id || 0, newUploads)
            .then(() => {
                repair.uploads = newUploads;
            });
    }

    private showMessageDialog(repair: Repair) {
        if (repair.managers) {
            this.messageProjectManagers = repair.managers.map((m) => m.abbreviation || '')
                .filter((a) => !!a);
            this.messageRepair = repair;
        }
    }

    private calculateHeaderTranslateY() {
        const table: HTMLElement | null = document.getElementById(`table_${this.id}`);
        const tableHeader: HTMLElement | null = document.getElementById(`table_head_${this.id}`);
        const siteHeader: HTMLElement | null = document.getElementById('header');
        if (table && tableHeader && siteHeader) {
            const translateY = siteHeader.getBoundingClientRect().bottom - table.getBoundingClientRect().top;
            const lowerLimit = 0;
            const upperLimit = table.getBoundingClientRect().height - tableHeader.getBoundingClientRect().height;
            this.headerTranslateY = Math.min(Math.max(translateY, lowerLimit), upperLimit);
        }
    }

}
